<template>
  <ui-side-modal v-show="showing"
                 class="template-detail"
                 :width="modalWidth"
                 @close="closeModal">
    <ui-form v-if="dataOption && showing"
             :meta="dataOption"
             :data="model"
             :error="error"
             @set="setData"
             @error="setError"
             @input="input">
      <slot></slot>
      <ui-button :color="'gray'"
                 @click="save(false)">
        <i class="xi-close"></i> 취소
      </ui-button>
      <ui-button :color="'red'"
                 @click="save(true)">
        <i class="xi-pen"></i> 저장
      </ui-button>
    </ui-form>
  </ui-side-modal>
</template>

<script>
import isobject from 'isobject'
import { EventBus } from '@/utils/event-bus'
import { alertError, isEndWithConsonant } from '@/utils/tools'
import { DEFAULT_TIMEZONE_NAME, POSITION_MY_JAM, POSITION_BA_APPLY_BASKET, JAM_USE_ERR_MSG } from '@/utils/constants'
import { startOfDay } from 'date-fns'
import { getTimezoneOffset } from 'date-fns-tz'

import UiForm from '@/components/_ui/UiForm'
import UiButton from '@/components/_ui/UiButton'
import UiSideModal from '@/components/_ui/UiSideModal'
import { isValidDomain } from '@/libs/validation-helper'

export default {
  name: 'TemplateInsert',
  components: {
    UiForm,
    UiButton,
    UiSideModal
  },
  props: {
    show: {
      type: Boolean,
      default: false,
      required: false
    },
    metaApi: {
      required: true
    },
    insertApi: {
      required: true
    },
    resultMsg: {
      default: false
    },
    service: {
      default: false
    },
    menu: {
      default: false
    },
    makePayment: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.loadMeta()
      } else {
        this.showing = false
        this.model = { ...this.initialModel }
      }
    }
  },
  created () {
    this.showing = this.show
    this.model = { ...this.initialModel }
  },
  computed: {
    modalWidth () {
      if (this.dataOption) {
        for (const option of Object.values(this.dataOption)) {
          if (option.type === 'editor' || option.type === 'editor4') {
            return 1200
          }
        }
      }
      return null
    }
  },
  methods: {
    loadMeta (callback = null) {
      this.error = {}
      this.showing = false
      EventBus.$emit('onLoading', true)
      setTimeout(() => {
        this.metaApi().then(response => {
          this.dataOption = response.result || null
          this.showing = true
          if (this.dataOption) {
            for (const [field, option] of Object.entries(this.dataOption)) {
              if (option.default) {
                this.$set(this.model, option.field || field, option.default)
              }
            }
          }
        }).catch(error => {
          alertError('메타 데이터를 불러오지 못 했습니다.', error)
        }).finally(() => {
          if (callback) {
            callback()
          }
          EventBus.$emit('onLoading', false)
        })
      }, 300)
    },
    setData (data) {
      if ('paymentIdFind' in data) {
        if (this.$route.meta.ba ? 'ba' : 'beusable' === 'ba') {
          this.userData.baPlanNumber = data.baPlanNumber
          if (this.userData.baCredit !== null && this.userData.baCredit.remainPV !== null) {
            this.userData.baCredit.remainPV = null
          }
        } else {
          this.userData.beusPlanNumber = data.beusPlanNumber
          if (this.userData.beusCredit !== null && this.userData.beusCredit.remainPV !== null) {
            this.userData.beusCredit.remainPV = null
          }
        }
        data = this.userData
      } else {
        this.userData = data
      }
      if (this.model.paymentType === 'JAM') {
        if (data.totalRemainJam) {
          this.$delete(this.error, 'paymentType')
          this.dataOption.pvPrice.static = false
          if (this.model.pvPrice && this.model.pvPrice > data.totalRemainJam) {
            this.$set(this.error, 'pvPrice', `보유 잼 개수가 초과되었습니다.`)
          } else if (this.model.pvPrice && this.model.pvPrice < data.totalRemainJam) {
            this.$delete(this.error, 'pvPrice')
          }
        } else {
          this.dataOption.pvPrice.static = true
        }
      }
      if (this.dataOption) {
        for (const [field, option] of Object.entries(this.dataOption)) {
          const key = option.field || field
          if (key.includes('.')) {
            const splitedKey = key.split('.')
            const key1 = splitedKey[0]
            const key2 = splitedKey[1]
            if (data[key1] && data[key1][key2] !== undefined) {
              this.$set(this.model, key, data[key1][key2])
            } else if (option.static) {
              this.$delete(this.model, key)
            }
          } else {
            if (data[key] !== undefined) {
              this.$set(this.model, key, data[key])
            } else if (option.static) {
              this.$delete(this.model, key)
            }
          }
        }
        if (data.timezoneName) {
          if (!data.timezone) {
            let tz = getTimezoneOffset(data.timezoneName) / 60 / 60 / 1000;
            const splittedTz = tz.toString().split('.')
            if (splittedTz.length > 1 && splittedTz[1].length ===1) {
              splittedTz[1] = splittedTz[1] + '0'
            }
            if (tz <= -10) {
              if (splittedTz.length > 1) {
                tz = splittedTz[0] + ':' + 60 * splittedTz[1] / 100
              } else {
                tz = tz + ":00"
              }
            } else if (tz > -10 && tz < 0) {
              if (splittedTz.length > 1) {
                tz = '-0' + splittedTz[0][1] + ':' + 60 * splittedTz[1] / 100
              } else {
                tz = '-0' + tz.toString()[1] + ":00"
              }
            } else if (tz >= 0 && tz < 10) {
              if (splittedTz.length > 1) {
                tz = '+0' + splittedTz[0] + ':' + 60 * splittedTz[1] / 100
              } else {
                tz = "+0" + tz + ":00"
              }
            } else if (tz >= 10) {
              if (splittedTz.length > 1) {
                tz = '+' + splittedTz[0] + ':' + 60 * splittedTz[1] / 100
              } else {
                tz = "+" + tz + ":00"
              }
            }
            data.timezone = tz
          }

          this.$delete(this.model, 'startDate')
          this.$delete(this.model, 'endDate')
          this.model.timezone = data.timezone
          if (this.dataoption && this.dataoption.startDate) {
            this.dataOption.startDate.static = false
          }
          if (this.dataoption && this.dataOption.endDate) {
            this.dataOption.endDate.static = false
          }
        } else {
          this.model.timezone = null
          this.dataOption.timezoneName.static = false
          this.dataOption.startDate.static = true
          this.dataOption.endDate.static = true
        }
      }
    },
    setError (key, message) {
      this.$set(this.error, key, message)
    },
    checkRequired () {
      if (this.dataOption) {
        if (this.menu === 'jamUse') {
          for (const [field, option] of Object.entries(this.dataOption)) {
            const key = option.field || field
            if (option.required && !this.model[key]) {
              if (key === 'jam') {
                this.$set(this.error, key, `${option.name} 개수 입력은 필수입니다.`)
              } else if (key === 'to') {
                this.$set(this.error, key, `${option.name} 선택은 필수입니다.`)
              } else {
                this.$set(this.error, key, `${option.name}${isEndWithConsonant(option.name) ? '은' : '는'} 필수입니다.`)
              }
            } else if (option.required && this.model[key]) {
              if (key === 'jam') {
                if (this.model.from === POSITION_MY_JAM) {
                  if (this.model[key] > this.userData.totalRemainJam) {
                    this.$set(this.error, key, `이동할 ${option.name}이 ${this.dataOption.totalRemainJam.name} 보다 많습니다`)
                  }
                } else if (this.model.from === POSITION_BA_APPLY_BASKET) {
                  if (this.model[key] > this.userData.basketJam) {
                    this.$set(this.error, key, `이동할 ${option.name}이 ${this.dataOption.basketJam.name} 보다 많습니다`)
                  }
                }
              }
            }
          }
        } else {
          for (const [field, option] of Object.entries(this.dataOption)) {
            const key = option.field || field
            if (option.required && !this.model[key] && this.model[key] !== 0) {
              this.$set(this.error, key, `${option.name}${isEndWithConsonant(option.name) ? '은' : '는'} 필수입니다.`)
            } else {
              if (key === 'expireDate') {
                const today = startOfDay(new Date())
                if (today > startOfDay(new Date(this.model[key]))) {
                  this.$set(this.error, key, `${option.name}${isEndWithConsonant(option.name) ? '은' : '는'} 오늘 날짜 이후로만 설정 가능합니다.`)
                }
              }
            }
          }
        }
      }
      if (this.menu === 'chargePV' && this.dataOption.pvPrice.static && this.model.paymentType === 'JAM' && (this.model.totalRemainJam === 0 || this.model.totalRemainJam === null)) {
        this.$set(this.error, 'paymentType', `보유한 JAM이 없습니다.`)
      }
      for (const message of Object.values(this.error)) {
        if (message) {
          return false
        }
      }
      return true
    },
    save (save = false) {
      let confirmed = false
      if (this.checkRequired()) {
        confirmed = confirm(save ? '정말로 저장하시겠습니까?' : '정말로 저장하지 않고 끝내시겠습니까?')
      } else {
        if (!save) {
          confirmed = confirm(save ? '정말로 저장하시겠습니까?' : '정말로 저장하지 않고 끝내시겠습니까?')
        }
      }

      if (confirmed) {
        if (save) {
          EventBus.$emit('onLoading', true)
          if (this.$route.meta.jam) {
            this.model.serviceType = 'ba'
          }
          if (!this.model.paymentId) {
            let service = this.$route.meta.ba ? 'ba' : 'beusable'
            if (this.makePayment && service === 'ba') {
              this.model.paymentId = this.userData.baPaymentId
            } else if (this.makePayment && service === 'beusable') {
              this.model.paymentId = this.userData.beusPaymentId
            }
          }
          this.insertApi(this.model, true).then(response => {
            this.$emit('close')
            this.$emit('saved', response.result)
          }).catch(error => {
            let message = false
            if (this.menu === 'chargePV' || this.menu === 'jamUse') {
              message = error.response ? error.response.data.message.failedReason : false
              error.message = error.response.data.message.failedReason
            } else {
              message = error.response ? error.response.data.message : false
            }
            if (isobject(message)) {
              for (const [key, value] of Object.entries(message)) {
                if (key !== 'idx') {
                  this.$set(this.error, key, value)
                }
              }
            }
            if (this.menu === 'paymentList' || this.menu === 'chargePV') {
              alertError('결제에 실패했습니다.', error.message || error)
              if (error.response.data.message.keyWord === 'jamPvCharge') {
                this.$set(this.model, 'totalRemainJam', error.response.data.message.totalRemainJam)
                this.$set(this.error, 'pvPrice', `보유 잼 개수가 초과되었습니다.`)
              }
            } else {
              if (error.response.data.message.keyWord === 'jamUse') {
                alertError('저장에 실패했습니다.', JAM_USE_ERR_MSG[error.message])
              } else {
                alertError('저장에 실패했습니다.', error.message || error)
              }
            }
          }).finally(() => {
            if (this.resultMsg) {
              alert(this.resultMsg)
            }
            this.$emit('reload')
            EventBus.$emit('onLoading', false)
          })
        } else {
          this.$emit('close')
        }
      }
    },
    input (field, value) {
      // if (this.service === 'beusable') {
      this.$delete(this.error, field)

      if (this.menu === 'plan') {
        if (field === 'planNumber') {
          let valueCheck = false
          if (this.service === 'beusable') {
            valueCheck = (value >= 100 && value < 200)
          } else {
            valueCheck = (value <= 300)
          }
          if (valueCheck) {
            if (this.dataOption.paymentType) {
              if (value === 300) {
                this.$set(this.model, 'paymentType', 'Trial')
              } else {
                this.$set(this.model, 'paymentType', 'N/A')
              }
              this.dataOption.paymentType.static = true
            }

            if (this.dataOption.price) {
              this.$set(this.model, 'price', '0')
              this.dataOption.price.static = true
            }
          } else {
            if (this.dataOption.paymentType) {
              this.$set(this.model, 'paymentType', '')
              // delete this.dataOption.paymentType.default
              this.dataOption.paymentType.static = false

              this.dataOption.paymentType.options.forEach(item => {
                if (item.value === 'N/A' || item.value === 'coupon') {
                  item.disabled = true
                }
              })
            }

            if (this.dataOption.price) {
              this.$set(this.model, 'price', null)
              this.dataOption.price.static = false
            }
          }
        }

        if (field === 'paymentType') {
          if (value === 'card') {
            if (this.dataOption.isLastPaidPlan) {
              this.$set(this.model, 'isLastPaidPlan', false)
              this.dataOption.isLastPaidPlan.static = false
            }
          } else {
            if (this.dataOption.isLastPaidPlan) {
              this.$set(this.model, 'isLastPaidPlan', false)
              this.dataOption.isLastPaidPlan.static = true
            }
          }

          if (value === 'N/A' || value === 'trial' || value === 'coupon' || value === '4grit') {
            if (this.dataOption.price) {
              this.$set(this.model, 'price', '0')
              this.dataOption.price.static = true
            }
          } else {
            if (this.dataOption.price) {
              this.$set(this.model, 'price', null)
              this.dataOption.price.static = false
            }
          }
        }
      }

      if (this.menu === 'apply') {
        if (field === 'url' && value) {
          const matchType = this.model.matchType
          const targetMatchType = ['contains', 'regexp', 'end']

          if (matchType && targetMatchType.includes(matchType)) {
            if (!isValidDomain(value)) {
              this.setError(field, '도메인 주소만 입력해주세요')
            }
          }
        }

        if (field === 'pvLimit') {
          if (value === 0) {
            this.setError(field, '0은 입력이 불가합니다.')
          }
        }

        if ((field === 'startDate' || field === 'endDate' || field === 'regDate') && value) {
          const now = new Date()
          if (this.model.timezoneName !== DEFAULT_TIMEZONE_NAME) {
            const tz = getTimezoneOffset(this.model.timezoneName) / 60 / 60 / 1000
            now.setHours(now.getHours() + tz - 9)
            const valueDate = new Date(value)

            if (startOfDay(valueDate) < startOfDay(now)) {
              this.setError(field, '지난 날짜는 설정할 수 없습니다')
            }
          } else {
            now.setDate(now.getDate() - 1)
            if (new Date(value) < now) {
              this.setError(field, '지난 날짜는 설정할 수 없습니다')
            }
          }
        }

        if (field === 'matchType' && value) {
          this.$delete(this.error, 'url')
          const matchType = value
          const targetMatchType = ['contains', 'regexp', 'end']

          if (targetMatchType.includes(matchType)) {
            const url = this.model.url
            if (!isValidDomain(url)) {
              this.setError('url', '도메인 주소만 입력해주세요')
            }

            this.dataOption.matchString.required = true
          } else {
            delete this.dataOption.matchString.required
          }
        }

        if (field === 'bLogin') {
          const captureURL = this.model.captureURL

          if (captureURL && value === true) {
            this.setError('captureURL', '로그인 이후 페이지로 설정 시, 캡쳐URL 입력이 불가합니다.')
          }
        }

        if (field === 'captureURL') {
          if (value) {
            const bLogin = this.model.bLogin
            if (bLogin) {
              this.setError('captureURL', '로그인 이후 페이지로 설정 시, 캡쳐URL 입력이 불가합니다.')
            }
          }
        }

        if (field === 'timezoneName') {
          if (value) {
            this.setData({ timezoneName: value })
          }
        }
      }

      if (this.menu === 'chargePV' && field === 'paymentType') {
        this.$delete(this.error, 'pvPrice')
        if (value === 'JAM') {
          if (this.model.totalRemainJam === 0 || this.model.totalRemainJam === null) {
            this.dataOption.pvPrice.static = true
          } else {
            this.dataOption.pvPrice.static = false
            if (this.model.totalRemainJam < this.model.pvPrice) {
              this.$set(this.error, 'pvPrice', `보유 잼 개수가 초과되었습니다.`)
            } else {
              this.$delete(this.error, 'pvPrice')
            }
          }
        } else {
          this.dataOption.pvPrice.static = false
        }
      }

      if (this.menu === 'jamUse') {
        if (field === 'from' && value) {
          this.dataOption.to.static = false
          this.dataOption.jam.static = false
          let toSelectOption = []
          this.dataOption.to.options.forEach(item => {
            if (item.value === value) {
              item.disabled = true
            } else {
              item.disabled = false
            }
            toSelectOption.push(item)
          })
          this.dataOption.to.options = toSelectOption
        }
      }

      if (field === 'pvPrice' && this.model.paymentType === 'JAM' && this.dataOption.pvPrice.static === false) {
        if (value > this.model.totalRemainJam) {
          this.$set(this.error, field, `보유 잼 개수가 초과되었습니다.`)
        } else {
          this.$delete(this.error, field)
        }
      }

      this.$set(this.model, field, value)
      this.$emit('input', field, value)
    },
    closeModal () {
      const confirmed = confirm('정말로 저장하지 않고 끝내시겠습니까?')
      if (confirmed) {
        this.$emit('close')
      }
    }
  },
  data () {
    return {
      showing: false,
      dataOption: null,
      model: {},
      userData: null,
      initialModel: {
        serviceType: this.$route.meta.ba ? 'ba' : 'beusable'
      },
      error: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
}
</style>
